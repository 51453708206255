import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { LoadingButton } from "@mui/lab";
import { PATH_DASHBOARD, PATH_AUTH } from "routes/paths";
import { CustomAvatar } from "components/custom-avatar";
import { useSnackbar } from "components/snackbar";
import MenuPopover from "components/menu-popover";
import { IconButtonAnimate } from "components/animate";
import { toastoptions } from "utils/toastoptions";
import { toast } from "react-hot-toast";
import ConfirmDialog from "components/confirm-dialog/ConfirmDialog";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RateUs from "pages/dashboard/RateUs/RateUs";
import { addUserSpendTimeAsync } from "redux/async.api";
import { setLangData } from "redux/language/languageData.slice";
import "./styles.css";
import { LanguageConvertAsync } from "redux/language/language.async";
import LanguageDialog from "./LanguageDialog";

export default function AccountPopover() {
  const navigate = useNavigate();
  const user = {};
  const { studentById } = useSelector((state) => state?.student);
  const { avatar, name } = studentById;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [openPopover, setOpenPopover] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [langOpen, setLangOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };
  const [language, setLanguage] = useState("");
  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const { languageAsync } = useSelector((state) => state.languageData);
  const languageData = useSelector((state) => state.dataLangDetail);

  const getLanguage = localStorage.getItem("lang");
  useEffect(() => {
    if (getLanguage) {
      const payload = {
        language: getLanguage,
      };
      dispatch(LanguageConvertAsync(payload));
    }
  }, []);

  const handleLanguageClose = () => {
    setLangOpen(false);
    setOpenPopover(null);
  };

  const langPopupOpen = () => {
    setLangOpen(true);
  };

  const OPTIONS = [
    {
      label: languageAsync.home ? languageAsync.home : "Home",
      linkTo: "/",
    },
    {
      label: languageAsync.helpsupport
        ? languageAsync.helpsupport
        : "Help & Support",
      linkTo: PATH_DASHBOARD.helpAndSupport,
    },
    {
      label: "Change Password",
      linkTo: PATH_DASHBOARD.changePassword,
    },
    {
      label: languageAsync.profile ? languageAsync.profile : "Profile",
      linkTo: PATH_DASHBOARD.UpdateProfile,
    },
    // {
    //   label: "Subscription",
    //   linkTo: PATH_DASHBOARD.subscription,
    // },
    // {
    //   label: "Refer & Earn",
    //   linkTo: PATH_DASHBOARD.referearn,
    // },
    // {
    //   label: languageAsync.learningreport
    //     ? languageAsync.learningreport
    //     : "My Learning Reports",
    //   linkTo: PATH_DASHBOARD.myLearningReports,
    // },
    // {
    //   label: languageAsync.recentactivity
    //     ? languageAsync.recentactivity
    //     : "Recent Activity",
    //   linkTo: PATH_DASHBOARD.recentActivity,
    // },
  ];

  const handleLogout = async () => {
    try {
      handleClosePopover();
      dispatch(
        addUserSpendTimeAsync({
          status: "close",
        })
      );
      dispatch({ type: "LOGOUT" }); // define code in store in action.type === 'LOGOUT'
      toast.success("Logged out Successfully");
    } catch (error) {
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  const languageSet = (val) => {
    setLanguage(val);
    localStorage.setItem("lang", val);
    dispatch(setLangData(val));
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar src={avatar} alt={name} name={name} />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleClickItem(option.linkTo)}
            >
              {option.label}
            </MenuItem>
          ))}
          <MenuItem onClick={handleOpen}>
            {languageAsync.rateus ? languageAsync.rateus : "Rate Us"}
          </MenuItem>
          <MenuItem onClick={langPopupOpen}>Language</MenuItem>

          {/*<div class="dropdown" style={{ float: "left" }}>
            <MenuItem className="dropbtn">
              {languageAsync.language ? languageAsync.language : "Language"}
            </MenuItem>
            <div class="dropdown-content">
              <div className="inner-div" onClick={() => languageSet("hindi")}>
                Hindi
              </div>
              <div className="inner-div" onClick={() => languageSet("english")}>
                English
              </div>
            </div>
          </div> */}
        </Stack>
        <MenuItem
          onClick={() => {
            handleClosePopover();
            setOpenConfirm(true);
          }}
          sx={{ m: 1 }}
        >
          {languageAsync.logout ? languageAsync.logout : "Logout"}
        </MenuItem>
      </MenuPopover>
      <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title={languageAsync.logout ? languageAsync.logout : "Logout"}
        content={
          languageAsync.logoutmsg
            ? languageAsync.logoutmsg
            : "Are you sure want to Logout?"
        }
        cancelPopup={languageAsync.cancel}
        action={
          <LoadingButton
            variant="contained"
            color="error"
            onClick={handleLogout}
            sx={{ m: 1 }}
          >
            {languageAsync.yes ? languageAsync.yes : "Yes"}
          </LoadingButton>
        }
      />
      <RateUs open={open} setOpen={setOpen} />
      <LanguageDialog
        open={langOpen}
        handleClose={handleLanguageClose}
        setOpenPopover={setOpenPopover}
      />
    </>
  );
}
