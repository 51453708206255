import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { updateUserPasswordAsync } from "./changePassword.async";

const initialState = {
  updatePasswordLoader: false,
  updatePassword: [],
};

export const changePasswordSlice = createSlice({
  name: "register",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(updateUserPasswordAsync.pending), (state) => {
      state.updatePasswordLoader = true;
    });

    builder.addMatcher(
      isAnyOf(updateUserPasswordAsync.fulfilled),
      (state, action) => {
        state.updatePasswordLoader = false;
        state.updatePassword = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateUserPasswordAsync.rejected),
      (state, action) => {
        state.updatePasswordLoader = false;
      }
    );
  },

  reducers: {
    emptychangePassword: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptychangePassword } = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
